<template>
  <div class="skeleton" :class="'skeleton-' + skeletonType">
    <!--  -->
  </div>
</template>

<script>
export default {
  props: {
    skeletonType: {
      type: String,
      required: true,
    },
  },
};
</script>
